/**
 * @Date: 2022-07-14 10:53:10
 * @LastEditTime: 2022-07-14 10:53:10
 * @Description:  API - CMS模块
 */
import axios from '@/common/js/request'
// import QS from 'qs'
const cms = {
	// 文章 - 列表
	apiCmsList(data, loading = true) {
		return axios.get('/api/CMS/open/Article_GetList', { params: data, loading })
	},
	// 文章 - 分类列表
	apiCmsCategoryList(data, loading = true) {
		return axios.get('/api/CMS/open/Articleclass_GetChannel',{ params: data, loading })
	},
	// 文章 - 详情
	apiCmsDetail(data, loading = true) {
		return axios.get('/api/CMS/open/Article_Details', { params: data, loading })
	},
		// 字典--列表
	apiCmsDictitmecmsGetList(data, loading = true) {
		return axios.get('/api/CMS/open/Dictitmecms_GetList', { params: data, loading })
	},
	// 文章分类--N级树形列表
	apiCmsGetTreeSelect(data, loading = true) {
		return axios.get('/api/CMS/open/Articleclass_GetTreeSelect', { params: data, loading })
	},
}

export default cms
