/**
 * @Date: 2022-07-14 16:18:00
 * @LastEditTime: 2022-07-14 16:18:00
 * @Description:  静态资源图片
 */


const hostBaseUrl = "http://img.njtsq.com/img/" // 远程服务器图片地址
const localBaseUrl = '/static/images/' // 本地图片地址
/*
 * 图片静态资源表，所有图片资源路径在这统一管理，不应该写死在页面中
 * 页面使用：this.$assetsImg.xx
 * CSS背景：应尽量使用:style="" 行内样式设置背景图
 * PATH说明：本地路径或者服务器路径
 */

const assetsImg = {
	homebj: `${hostBaseUrl}home01.png`, // 首页背景
	logo: `${hostBaseUrl}logo.jpg`, // 首页背景
	homeen01: `${hostBaseUrl}home-en-1.png`, // 首页英文背景
	homeen02: `${hostBaseUrl}home-en-2.png`, // 首页英文背景
	nav01: `${hostBaseUrl}nav01-en.png`, // 英文背景
	nav02: `${hostBaseUrl}nav02-en.png`, // 英文背景
	nav03: `${hostBaseUrl}nav03-en.png`, // 英文背景
	nav04: `${hostBaseUrl}nav04-en.png`, // 英文背景
	b1: `${hostBaseUrl}b1.png`, // 导航背景
	b2: `${hostBaseUrl}b2.png`, // 导航背景
	b3: `${hostBaseUrl}b3.png`, // 导航背景
	b4: `${hostBaseUrl}b4.png`, // 导航背景
	b5: `${hostBaseUrl}b5.png`, // 导航背景
	b6: `${hostBaseUrl}b6.png`, // 导航背景
	b7: `${hostBaseUrl}b7.png`, // 导航背景
	b8: `${hostBaseUrl}b8.png`, // 导航背景
}

export default assetsImg