/**
 * @Date: 2020-03-20 14:26:30
 * @LastEditTime: 2021-06-28 17:28:10
 * @Description: 封装 axios 请求
 */

import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'
import config from './config'

// 请求失败后的错误统一处理（HTTP状态码）
const httpErrorHandle = statusCode => {
  switch (statusCode) {
    case 400:
      Message.error('请求错误(400)')
      break // 400请求错误
    case 401: // 401: 未授权，请登录
      Message.error('401 未授权')
      // toLogin()
      break
    case 403:
      Message.error('403 拒绝访问')
      break // 拒绝访问
    case 404:
      Message.error('404 请求出错')
      break // 404请求不存在
    case 405:
      Message.error('405 请求错误')
      break // 405请求不正确
    case 408:
      Message.error('408 请求超时')
      break // 408 请求超时
    case 415:
      Message.error('415 媒体类型')
      break // 415 contentType 错误
    case 500:
      Message.error('500 系统异常')
      break // 500 服务端异常
    case 501:
      Message.error('501 服务异常')
      break // 501 服务未实现
    case 502:
      Message.error('502 网络错误')
      break // 502 网络错误
    case 503:
      Message.error('503 服务异常')
      break // 503 服务不可用
    case 504:
      Message.error('504 网络超时')
      break // 504 网络超时
    default:
      Message.error('网络异常,请稍后再试') // 其他错误
  }
}

// 请求失败后的错误统一处理（后端自定义状态码）
const customErrorHandle = (statusCode, msg) => {
  switch (statusCode) {
    case 1001: // token 失效
      Message.error('1001 请求错误')
      break
    case 400:
      Message.error('400 请求错误')
      break
    case 401:
      Message.error('401 未授权')
      // toLogin()
      break
    case 403:
      Message.error('403 拒绝访问')
      break
    case 404:
      Message.error('404 请求出错')
      break
    case 405:
      Message.error('405 请求错误')
      break
    // case 500:
    //   Message.error('500 系统异常')
    //   break
    case 503:
      Message.error('503 服务异常')
      break
    default:
      Message.error(msg) // 其他错误
  }
}

// Loading 开启
let requestNum = 0 // 存储请求个数
let loadingInstance = null
// Loading 关闭
const hideLoading = loading => {
  if (loading) {
    if (requestNum <= 0) return
    requestNum--
    if (requestNum === 0) { Vue.nextTick(() => { loadingInstance.close() }) }
  }
}
// 创建 axios 实例
const instance = axios.create({
  // baseURL: '/api', // 跨域
  baseURL:config.apiUrl,
  timeout: 1000 * 20 // 超时时间
})
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // console.log(config)
    // config.headers['Content-Type'] = config.contentType === 'form' ? 'application/x-www-form-urlencoded;charset=UTF-8' : 'application/json'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
   
    // showLoading(config.loading, config.loadingText || '加载中...')
    return config
  },
  error => { // 请求失败
    console.log('请求失败：' + error)
    Message.error(error.message)
    return Promise.error(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  response => { // 响应成功
    hideLoading(response.config.loading)
    // const code = response.data.code
    if (response.data.success) {
      return Promise.resolve(response.data)
    } else {
      if (!response.config.isErrorMsgHide) {
        let msg = ''
        try {
          msg = JSON.parse(response.data.msg).message
        } catch (e) {
          msg = response.data.msg || response.data.mesg || response.data.message
        }
        customErrorHandle(response.data.code, msg)
      }
      // return response.data
      return Promise.reject(response.data)
    }
  },
  error => { // 响应失败
    console.log('响应失败：' + error)
    hideLoading(error.config.loading)
    const { response } = error
    if (response) { // !200 响应
      if (!response.config.isErrorMsgHide) {
        httpErrorHandle(response.status)
      }
      return Promise.reject(response)
    } else { // 断网情况
    
      Message.error('连接失败')
    }
  }
)
export default instance
