/**
 * @Date: 2022-07-14 10:53:10
 * @LastEditTime: 2022-07-14 10:53:10
 * @Description:  API - 通用模块
 *
 * application/x-www-form-urlencoded 和 QS.stringify(data) 须同时设置;
 * params: get/delete   data: post/put/patch
 * request(url, get/post, data, loading, loadingText, contentType: 'json/form', isErrorMsgHide: true/false )
 *   根据后端返回的数据类型，更换参数 ↓
 *   responseType: 'arraybuffer' => response 是一个包含二进制数据的 JavaScript ArrayBuffer；
 *   responseType: 'blob' =>  blob => response 是一个包含二进制数据的 Blob 对象；
 *
 */
// baseUrl 可配置在request.js中，也可拼接在API文件中。当有多个鉴权模块，放在API文件中更灵活
import axios from '@/common/js/request'
import QS from 'qs'
const common = {	
	// 配置 - 项目配置信息
	apiCommonWebInfo(data, loading = true) {
		return axios.post('/api/Manage/open/webInfo', { params: data, loading })
	},
	// 片段 - 单条记录By主键
	apiCommonFragmentDetail(data, loading = true) {
		return axios.get('/api/Manage/open/Fragment_GetById', { params: data, loading })
	},
	// 图片管理 - 获取图片
	apiCommonImgList(data, loading = true) {
		return axios.get('/api/Manage/open/Imagelist_GetById', { params: data, loading })
	},
    // 业务字典--根据分类ID获取
	apiCommonBusinessitemGetByClassid(data, loading = true) {
		return axios.get('/api/Manage/open/businessitem_getByClassid', { params: data, loading })
	},
		// 配置 - 项目配置信息
	apiMessageboardEdit(data, loading = true) {
		return axios.post('/api/Manage/open/Messageboard_Edit',  QS.stringify(data), { loading })
	},
	//天气--获取高德地图天气信息
	apiAreaGetWeather(data, loading = true) {
		return axios.get('/api/Tool/open/Area_getWeather',  { params: data, loading })
	},
}

export default common
