import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'index',
     component: () => import('../views/index.vue')
  },
  {
    path: '/details:tid',
    name: 'details',
     component: () => import('../views/details/details.vue')
  },
  {
    path: '/browse:tid',
    name: 'browse',
     component: () => import('../views/details/browse.vue')
  }, {
    path: '/video:tid',
    name: 'video',
     component: () => import('../views/details/video.vue')
  },
    {
    path: '/category/:cid/:tid',
    name: 'category',
     component: () => import('../views/category.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
