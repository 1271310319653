import Vue from "vue"
import api from '@/api'
import VueI18n from "vue-i18n"

import zh from './lang/zh'
import en from './lang/en'
import jap from './lang/jap'
import kor from './lang/kor'


import ElementLocale from 'element-ui/lib/locale'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import koLocale from 'element-ui/lib/locale/lang/ko'

Vue.prototype.$api = api
ElementLocale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换
Vue.use(VueI18n) //注入到所有的子组件


// let langFileds = require.context('./lang', false, /\.js$/)

// let regExp = /\.\/([^\.\/]+)\.([^\.]+)$/ //正则用于匹配 ./en.js中的'en'

// // regExp.exec('./en.js')

// let messages = {} //声明一个数据模型，对应i18n中的message属性
// langFileds.keys().forEach(key => {
// 	let prop = regExp.exec(key)[1] //正则匹配en|zh这样的值
// 	//messages[prop]相当于 messages['en'] = {table:{...}}
// 	messages[prop] = langFileds(key).default
// })
// let locale = localStorage.getItem('lang') || "zh" //从localstorag中获取

const i18n = new VueI18n({
	locale: localStorage.getItem('lang') || "zh", //将语言标识存入localStorage或sessionStorage中，页面刷新不会默认中文显示
	messages: {
		// 中文语言包
		'zh': {
			...zh,
			...zhLocale
		},
		//英文语言包
		'en': {
			...en,
			...enLocale
		},
		'jap': {
			...jap,
			...jaLocale
		},
		'kor': {
			...kor,
			...koLocale
		}
	},
	silentTranslationWarn: true, //解决vue-i18n黄色警告"value of key 'xxx' is not a string"和"cannot translate the value of keypath 'xxx'.use the value of keypath as default",可忽略
	globalInjection: true, // 全局注入
	fallbackLocale: 'zh', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文
});


export default i18n