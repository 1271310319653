/**
 * @Date: 2022-07-14 10:53:10
 * @LastEditTime: 2022-07-14 10:53:10
 * @Description:  API - 统一出口
 */

import common from '@/api/common' // 公共
import cms from '@/api/cms' // CMS
// 接口导出
export default { common, cms, }
