<template>
  <div id="app" class="page-component__scroll ">
    <el-backtop :bottom="120"><div class="c-el-backtop">
   <div class="u-font-28"><i class="el-icon-upload2"></i></div> 
    
	{{$t('foot.返回顶部')}}
    </div></el-backtop>
    <router-view />
    
  </div>
</template>

<script>
    export default {
      created() { },
      methods: {}
    }
</script>
<style lang="scss">
@import '@/assets/scss/common.scss';
.nourning {overflow-y:scroll;filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);-webkit-filter: grayscale(100%);}
body {
  margin: 0;
  padding: 0;

}
#app {
  margin: 0;
  padding: 0;
}
.head-bj {
  background-color: #bf001a;
  width: 100%;
}
.windowsWidth {
  width: 1500px;
  margin: 0 auto;
}

.router-link-active {
  text-decoration: none;
}
a {
  text-decoration: none;
  color: inherit;
}
.c-w-100 {
  width: 100%;
}
.c-font-color-c50000 {
  color: #c50000;
}
.c-font-color-b22712 {
  color: #b22712;
}
.c-font-color-015293 {
  color: #015293;
}
.c-font-color-030202 {
  color: #030202;
}
.c-font-color-909090 {
  color: #909090;
}
.c-font-color-565656 {
  color: #565656;
}
.c-font-color-999999 {
  color: #999999;
}
.c-font-color-ffffff {
  color: #fff;
}
.c-bg-fff {
  background-color: #fff;
}
.c-bg-757575 {
  background-color: #757575;
}
.c-bg-f4f3f3 {
  background-color: #f4f3f3;
}
.c-bg-c81313 {
  background-color: #c81313;
}
.c-dian {
  width: 8px;
  height: 25px;
  background-color: #c50000;
}
.c-border-b {
  border-bottom: solid 1px #eaeaea;
}
.c-border-b1 {
  border-bottom: solid 2px #c50000;
}
.c-border-t1 {
  border-top: solid 1px #c50000;
}
.c-dian-img1 {

}
.c-float-r {
  float: right;
}
.c-clear {
  clear: both;
}
.c-img-mixw img{max-width: 100%;}
.c-el-backtop {
  height: 70px;
  width: 100%;
  background-color: #f2f5f6;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  padding: 10px;
  color: #1989fa;
}
.image-slot{display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    // background: #f5f7fa;
    color: #909399;
    font-size: 54px;}
</style>
