import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/base.css'
import 'element-ui/lib/theme-chalk/index.css'
import assetsImg from '@/common/js/assetsImg' // 静态资源图片
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import api from '@/api' // API
import '@/filters'

import i18n from "./i18n"


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(ElementUI)



Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$api = api
Vue.prototype.$assetsImg = assetsImg

new Vue({
  el: '#app',
  router,
  store,
   i18n,
  render: h => h(App)
}).$mount('#app')
