export default {
	// ==============意见建议==============================
	opinion:{
		意见建议:"Suggestions",
		您的昵称:"Your Nickname",
		您的电话:"Your Phone Number",
		提交:"Submit",
		南京天生桥景区官方网站:"Nanjing Tiansheng Bridge Scenic Area Official Website",
		地址:"Address",
		江苏省南京市:"Nanjing, Jiangsu Province",
		邮编:"zip code",
		咨询电话:"Information Phone",
		救援电话:"Rescue Phone",
		投诉电话:"Complaint Phone",
		传真:"Fax",
		网址:"website",		
	},
	// ==============底部==============================
	foot:{
		景区咨询电话:"Scenic Area Information Phone",
		景区救援电话:"Scenic Area Rescue Phone",
		景区投诉电话:"Scenic Area Complaint Phone",
		客服邮箱:"Customer Service Email",
		网站备案号:"Website Registration Number",
		版权:"Copyright",
		推荐景点:"Recommended Attractions",
		天生桥:"Tiansheng Bridge",
		胭脂河:"Yanzhi River",
		胭脂峡:"Yanzhi Gorge",
		隐秀峡:"Yinxi Gorge",
		联盟景区:"Alliance Scenic Areas",
		天生桥景区:"Tiansheng Bridge Scenic Area",
		景区信息:"Scenic Area Information",
		最新公告:"Latest Announcements",	
		营业时间:"Business Hours",
		交通信息:"Transportation Information",
		车位大屏:"Parking Lot Display Screen",
		返回顶部:"Back to Top",	
	},
	// ==============天气==============================
	location: {
		当前位置: "Current Location",
		首页:"Home",
	},
	// ==============天气==============================
	weather: {
		最大日承载量: "Maximum Daily Capacity",
		预警日承载量: "Warning Daily Capacity",
		最大瞬时承载量: "Maximum Instant Capacity",
		合理日承载量: "Reasonable Daily Capacity",
		温度: "Temperature",
		体感温度: "Feels Like Temperature",
		风速: "Wind Speed",
		湿度: "Humidity",
		风向: "Wind Direction",
		能见度: "Visibility",
		人次:"Ten thousand people",
		秒:"second",
		级:"level",
		空气质量:"air quality",
	},

	// ==============菜单==============================
	navHome: 'Scenic Area Homepage',
	menu: [{
			"value": "1",
			"label": "Scenic Area Overview", //景区概况
			"img": null,
			"levels": 1,
			"path": "ItemChannelTxtImg",
			"href": null,
			"children": [{
					"value": "16",
					"label": "Historical Development", //历史沿革
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				},
				{
					"value": "12",
					"label": "Electronic Guide", //电子导览
					"img": null,
					"levels": 2,
					"path": "",
					"href": "https://720.z2ww.com/dist/#/previewwidthoutmodel?id=2d1c8f10-d0ce-424b-9f5e-325be542944f",
					"children": null
				},
				{
					"value": "11",
					"label": "Virtual Tour", //虚拟旅游
					"img": null,
					"levels": 1,
					"path": null,
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "2",
			"label": "Tour Guide", //导游导览
			"img": null,
			"levels": 1,
			"path": "ListChannelTxtImg",
			"href": null,
			"children": [{
					"value": "10",
					"label": "Recommended Attractions", //景点推荐
					"img": null,
					"levels": 1,
					"path": "ListClassImg",
					"href": null,
					"children": null
				},
				{
					"value": "13",
					"label": "Announcements", //通知公告
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "14",
					"label": "Work Updates", //工作动态
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "15",
					"label": "Event Preview", //活动预告
					"img": null,
					"levels": 2,
					"path": "ClassPublicImgSummany",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "3",
			"label": "Natural Scenery", //天生美景
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateImg",
			"href": null,
			"children": [{
					"value": "17",
					"label": "Video Appreciation", //视频赏析
					"img": null,
					"levels": 2,
					"path": "NewsVoideo",
					"href": null,
					"children": null
				},
				{
					"value": "18",
					"label": "Highlight Moments", //精彩时刻
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				},
				{
					"value": "19",
					"label": "Scenic Area Views", //景区风光
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "4",
			"label": "Travel Guide", //旅游攻略
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateListImg",
			"href": null,
			"children": [{
					"value": "20",
					"label": "Recommended Routes", //游线推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "21",
					"label": "Product Recommendations", //产品推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "22",
					"label": "Food Recommendations", //美食推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "23",
					"label": "Cultural Products", //文创推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "5",
			"label": "Service Information", //服务信息
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt",
			"href": null,
			"children": [{
					"value": "25",
					"label": "Transportation", //交通出行
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "26",
					"label": "Investment Cooperation", //招商合作
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "6",
			"label": "Online Reservation", //在线预定
			"img": null,
			"levels": 1,
			"path": null,
			"href": null,
			"children": null
		},
		{
			"value": "7",
			"label": "Customer Service", //客户服务
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt1",
			"href": null,
			"children": null
		}
	],

}