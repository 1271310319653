export default {
	// ==============意见建议==============================
	opinion:{
		意见建议:"意見・提案",
		您的昵称:"あなたのニックネーム",
		您的电话:"あなたの電話番号",
		提交:"提出",
		南京天生桥景区官方网站:"南京天生橋景区公式サイト",
		地址:"住所",
		江苏省南京市:"江蘇省南京市",
		邮编:"郵便番号",
		咨询电话:"お問い合わせ電話",
		救援电话:"救助電話",
		投诉电话:"苦情電話",
		传真:"ファックス",
		网址:"Webサイト",		
	},
	// ==============底部==============================
	foot:{
		景区咨询电话:"景区お問い合わせ電話",
		景区救援电话:"景区救助電話",
		景区投诉电话:"景区苦情電話",
		客服邮箱:"カスタマーサービスメール",
		网站备案号:"サイト登録番号",
		版权:"著作権",
		推荐景点:"おすすめ観光地",
		天生桥:"天生橋",
		胭脂河:"胭脂河",
		胭脂峡:"胭脂峡",
		隐秀峡:"隠秀峡",
		联盟景区:"アライアンス景区",
		天生桥景区:"天生橋景区",
		景区信息:"景区情報",
		最新公告:"最新公告",	
		营业时间:"営業時間",
		交通信息:"交通情報",
		车位大屏:"駐車場ディスプレイ画面",
		返回顶部:"ページトップへ戻る",	
	},
	// ==============天气==============================
	location: {
		当前位置: "現在位置",
		首页:"トップページ",
	},
	// ==============天气==============================
	weather: {
		最大日承载量: "最大日収容人数",
		预警日承载量: "警戒日収容人数",
		最大瞬时承载量: "最大瞬間収容人数",
		合理日承载量: "適正日収容人数",
		温度: "温度",
		体感温度: "体感温度",
		风速: "風速",
		湿度: "湿度",
		风向: "風向",
		能见度: "視程",
		人次: "万人次",
		秒:"秒",
		级:"レベル",
		空气质量:"くうきしつりょう",
	},

	// ==============菜单==============================
	navHome: '景区ホームページ',
	menu: [{
			"value": "1",
			"label": "景区概要", //景区概况
			"img": null,
			"levels": 1,
			"path": "ItemChannelTxtImg",
			"href": null,
			"children": [{
					"value": "16",
					"label": "歴史沿革", //历史沿革
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				},
				{
					"value": "12",
					"label": "電子ガイド", //电子导览
					"img": null,
					"levels": 2,
					"path": "",
					"href": "https://720.z2ww.com/dist/#/previewwidthoutmodel?id=2d1c8f10-d0ce-424b-9f5e-325be542944f",
					"children": null
				},
				{
					"value": "11",
					"label": "バーチャルツアー", //虚拟旅游
					"img": null,
					"levels": 1,
					"path": null,
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "2",
			"label": "ガイドツアー", //导游导览
			"img": null,
			"levels": 1,
			"path": "ListChannelTxtImg",
			"href": null,
			"children": [{
					"value": "10",
					"label": "おすすめ観光地", //景点推荐
					"img": null,
					"levels": 1,
					"path": "ListClassImg",
					"href": null,
					"children": null
				},
				{
					"value": "13",
					"label": "通知公告", //通知公告
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "14",
					"label": "作業動向", //工作动态
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "15",
					"label": "イベント予告", //活动预告
					"img": null,
					"levels": 2,
					"path": "ClassPublicImgSummany",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "3",
			"label": "天然美景", //天生美景
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateImg",
			"href": null,
			"children": [{
					"value": "17",
					"label": "ビデオ鑑賞", //视频赏析
					"img": null,
					"levels": 2,
					"path": "NewsVoideo",
					"href": null,
					"children": null
				},
				{
					"value": "18",
					"label": "ハイライトモーメント", //精彩时刻
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				},
				{
					"value": "19",
					"label": "景区の風景", //景区风光
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "4",
			"label": "旅行ガイド", //旅游攻略
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateListImg",
			"href": null,
			"children": [{
					"value": "20",
					"label": "おすすめルート", //游线推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "21",
					"label": "製品おすすめ", //产品推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "22",
					"label": "グルメおすすめ", //美食推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "23",
					"label": "文化製品おすすめ", //文创推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "5",
			"label": "サービス情報", //服务信息
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt",
			"href": null,
			"children": [{
					"value": "25",
					"label": "交通手段", //交通出行
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "26",
					"label": "投資協力", //招商合作
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "6",
			"label": "オンライン予約", //在线预定
			"img": null,
			"levels": 1,
			"path": null,
			"href": null,
			"children": null
		},
		{
			"value": "7",
			"label": "カスタマーサービス", //客户服务
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt1",
			"href": null,
			"children": null
		}
	],

}