export default {
	// ==============意见建议==============================
	opinion:{
		意见建议:"意见建议",
		您的昵称:"您的昵称",
		您的电话:"您的电话",
		提交:"提交",
		南京天生桥景区官方网站:"南京天生桥景区官方网站",
		地址:"地址",
		江苏省南京市:"江苏省南京市",
		邮编:"邮编",
		咨询电话:"咨询电话",
		救援电话:"救援电话",
		投诉电话:"投诉电话",
		传真:"传真",
		网址:"网址",		
	},
	// ==============底部==============================
	foot:{
		景区咨询电话:"景区咨询电话",
		景区救援电话:"景区救援电话",
		景区投诉电话:"景区投诉电话",
		客服邮箱:"客服邮箱",
		网站备案号:"网站备案号",
		版权:"版权",
		推荐景点:"推荐景点",
		天生桥:"天生桥",
		胭脂河:"胭脂河",
		胭脂峡:"胭脂峡",
		隐秀峡:"隐秀峡",
		联盟景区:"联盟景区",
		天生桥景区:"天生桥景区",
		景区信息:"景区信息",
		最新公告:"最新公告",	
		营业时间:"营业时间",
		交通信息:"交通信息",
		车位大屏:"车位大屏",
		返回顶部:"返回顶部",	
	},
	// ==============天气==============================
	location: {
		当前位置: "当前位置",
		首页:"首页"
	},
	// ==============天气==============================
	weather: {
		最大日承载量: "最大日承载量",
		预警日承载量: "预警日承载量",
		最大瞬时承载量: "最大瞬时承载量",
		合理日承载量: "合理日承载量",
		温度: "温度",
		体感温度: "体感温度",
		风速: "风速",
		湿度: "湿度",
		风向: "风向",
		能见度: "能见度",
		人次: "万人次",
		秒: "秒",
		级: "级",
空气质量:"空气质量",
	},
	// ==============菜单==============================
	navHome: '景区首页',
	menu: [{
			"value": "1",
			"label": "景区概况",
			"img": null,
			"levels": 1,
			"path": "ItemChannelTxtImg",
			"href": null,
			"children": [{
					"value": "16",
					"label": "历史沿革",
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				},
				{
					"value": "12",
					"label": "电子导览",
					"img": null,
					"levels": 2,
					"path": "",
					"href": "https://720.z2ww.com/dist/#/previewwidthoutmodel?id=2d1c8f10-d0ce-424b-9f5e-325be542944f",
					"children": null
				},
				{
					"value": "11",
					"label": "虚拟旅游",
					"img": null,
					"levels": 1,
					"path": null,
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "2",
			"label": "导游导览",
			"img": null,
			"levels": 1,
			"path": "ListChannelTxtImg",
			"href": null,
			"children": [{
					"value": "10",
					"label": "景点推荐",
					"img": null,
					"levels": 1,
					"path": "ListClassImg",
					"href": null,
					"children": null
				},
				{
					"value": "13",
					"label": "通知公告",
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "14",
					"label": "工作动态",
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "15",
					"label": "活动预告",
					"img": null,
					"levels": 2,
					"path": "ClassPublicImgSummany",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "3",
			"label": "天生美景",
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateImg",
			"href": null,
			"children": [{
					"value": "17",
					"label": "视频赏析",
					"img": null,
					"levels": 2,
					"path": "NewsVoideo",
					"href": null,
					"children": null
				},
				{
					"value": "18",
					"label": "精彩时刻",
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				},
				{
					"value": "19",
					"label": "景区风光",
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "4",
			"label": "旅游攻略",
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateListImg",
			"href": null,
			"children": [{
					"value": "20",
					"label": "游线推荐",
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "21",
					"label": "产品推荐",
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "22",
					"label": "美食推荐",
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "23",
					"label": "文创推荐",
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "5",
			"label": "服务信息",
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt",
			"href": null,
			"children": [{
					"value": "25",
					"label": "交通出行",
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "26",
					"label": "招商合作",
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "6",
			"label": "在线预定",
			"img": null,
			"levels": 1,
			"path": null,
			"href": null,
			"children": null
		},
		{
			"value": "7",
			"label": "客户服务",
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt1",
			"href": null,
			"children": null
		}
	],

}