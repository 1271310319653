import Vue from 'vue'
const vfilter = {
	/**
	 * 格式化日期时间
	 * @param {String|Number}    time 时间字符串 / 时间戳
	 * @param {String}    fmt 格式  yyyy-MM-dd hh:mm:ss
	 */
	filterTime(time, fmt = 'yyyy-MM-dd') {
		// fmt = fmt.replace(/-/g, "/")
		const date = time ? new Date(time) : new Date()
		const o = {
			'M+': date.getMonth() + 1, // 月份
			'd+': date.getDate(), // 日
			'h+': date.getHours(), // 小时
			'm+': date.getMinutes(), // 分
			's+': date.getSeconds(), // 秒
			'q+': Math.floor((date.getMonth() + 3) / 3) // 季度
			// 'S': date.getMilliseconds() // 毫秒
		}
		if (/(y+)/.test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
		}
		for (const k in o) {
			if (new RegExp('(' + k + ')').test(fmt)) {
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k])
					.length)))
			}
		}
		// const week = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][date.getDay()]
		// return fmt + '-' + week
		return fmt
	},
	/**
	 * 格式化 时间段
	 * @param {String|Number}    valueTime 时间字符串 / 时间戳
	 * @param {String}	type 类型 week / week2
	 */
	filterDateSlot(valueTime, type = 'week') {
		if (valueTime) {
			var newData = Date.parse(new Date())
			var oldData = Date.parse(new Date(valueTime.replace(/-/g, '/')))
			var diffTime = Math.abs(newData - oldData)
			if (diffTime > 7 * 24 * 3600 * 1000) {
				var date = new Date(valueTime.replace(/-/g, '/'))
				var y = date.getFullYear()
				var m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				var d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				// var h = date.getHours()
				// h = h < 10 ? ('0' + h) : h
				// var minute = date.getMinutes()
				// var second = date.getSeconds()
				// minute = minute < 10 ? ('1' + minute) : minute
				// second = second < 10 ? ('0' + second) : second
				return y + '-' + m + '-' + d
			} else if (diffTime < 7 * 24 * 3600 * 1000 && diffTime > 24 * 3600 * 1000) { // 一周之内
				if (type == 'week' || type == 'week2') {
					let date = new Date(valueTime.replace(/-/g, '/'))
					let nowDate = new Date()
					let time = date.toString().split(' ')[4].substr(0, 5)
					let diff = nowDate.getDay() - date.getDay()
					if (diff == 1) {
						return type == 'week' ? '昨天' : `昨天 ${time}`
					}
					let weekArr = ['日', '一', '二', '三', '四', '五', '六']
					return type == 'week' ? `星期${weekArr[date.getDay()]}` :
						`星期${weekArr[date.getDay()]} ${time}`
				}			
				return Math.floor(diffTime / (24 * 60 * 60 * 1000)) + '天前'

			} else if (diffTime < 24 * 3600 * 1000 && diffTime > 3600 * 1000) { // 一天之内
				if (type == 'week' || type == 'week2') {
					let date = new Date(valueTime.replace(/-/g, '/'))
					let nowDate = new Date()
					let time = date.toString().split(' ')[4].substr(0, 5)
					let diff = nowDate.getDay() - date.getDay()
					if (diff == 1) {
						return type == 'week' ? '昨天' : `昨天 ${time}`
					}
					return time
				}
				// var time = newData - diffTime;		
				return Math.floor(diffTime / (60 * 60 * 1000)) + '小时前'

			} else if (diffTime < 3600 * 1000 && diffTime > 60 * 1000) { // 一小时之内
				if (type == 'week' || type == 'week2') {
					let date = new Date(valueTime.replace(/-/g, '/'))
					let nowDate = new Date()
					let time = date.toString().split(' ')[4].substr(0, 5)
					let diff = nowDate.getDay() - date.getDay()
					if (diff == 1) {
						return type == 'week' ? '昨天' : `昨天 ${time}`
					}
					return time
				}				
				return Math.floor(diffTime / (60 * 1000)) + '分钟前'
			} else {
				if (type == 'week' || type == 'week2') {
					let date = new Date(valueTime.replace(/-/g, '/'))
					let nowDate = new Date()
					let time = date.toString().split(' ')[4].substr(0, 5)
					let diff = nowDate.getDay() - date.getDay()
					if (diff == 1) {
						return type == 'week' ? '昨天' : `昨天 ${time}`
					}
					return time
				}
				return '刚刚'
			}
		}
	},

	// 距离
	filterDistance(value) {
		let v = Number(value)
		if (v || v === 0) {
			return `距我 ${(v / 1000).toFixed(2)} km`
		} else {
			return '暂无距离信息'
		}
	},
	// 隐藏手机号中间四位
	filterHidePhone(phone) {
		return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
	},
	/**
	 * 字符串截取
	 * @param {String}   str 字符串
	 * @param {Number}   len 截取长度
	 * @param {String}   symbol 追加文本
	 */
	returnStrCut(str, len, symbol = '') {
		if (str) {
			const symbolTemp = str.length > len ? symbol : ''
			return str.substring(0, len) + symbolTemp
		}
	},
	/**
	 * 字符串替换
	 * @param {String}   str 字符串
	 * @param {String}   oldStr 需要替换的字符串  // str.replace(/需要替换的字符串/g, '新字符串')
	 * @param {String}   nowStr 新字符串
	 */
	returnStrReplace(str, oldStr, nowStr) {
		const reg = new RegExp(oldStr, 'g')
		if (str) {
			return str.replace(reg, nowStr)
		}
	},
	/**
	 * 字符 转换为 String 类型，null, 'null', undefined, 'undefined' 等转化为 ''
	 * @param {String}   str 字符串
	 */
	returnStrParse(str) {
		if (!str || str === 'null' || str === 'undefined') {
			return ''
		}
		return String(str)
	},
	

}
export default vfilter

for (let key in vfilter) {
	Vue.filter(key, vfilter[key])
}
