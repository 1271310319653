/**
 * @Date: 2022-07-14 14:55:25
 * @LastEditTime: 2022-07-14 14:55:25
 * @Description: 项目配置
 */

// api URL地址(单个baseUrl)
// const apiUrl = 'http://127.0.0.1:8099/' // 开发环境
const apiUrl = 'http://apiweb.njtsq.com/' // 正式环境
// 图片 URL地址
const imgUrl = ''


// 免登录白名单
const whiteList = [
]

export default {
	name: 'client', // 项目名
	version: '1.0.0', // 版本号
	apiUrl,
	imgUrl,
	whiteList
}
